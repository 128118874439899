import styled, { css, keyframes } from "styled-components";
import bgLeft from "../../images/bg/bg-hero-left.svg";
import bgRight from "../../images/bg/bg-hero-right.svg";
import bgRightLarge from "../../images/bg/bg-hero-right-large.svg";
import bgLeftLarge from "../../images/bg/bg-hero-left-large.svg";
import { Container } from "../Layout/LayoutElements.styled";

const verticalOpacity = keyframes`
	from {
		transform: translateY(-25px);
		opacity: 0;
	}
`;

const reveal = keyframes`
	to {
		transform: scaleY(0);
	}
`;

const bgRevealLeft = keyframes`
	from {
		transform: translateX(-30%);
		opacity: 0;
	}
`;

const bgRevealRight = keyframes`
	from {
		transform: translateX(30%);
		opacity: 0;
	}
`;

const bgs = css`
  content: "";
  position: absolute;
  min-height: 1000px;
  width: 100%;
  z-index: -1;
`;

export const Header = styled(Container)`
  --stagger-interval: 0.5s;
  --base-duration: 0.8s;
  --base-delay: 0.5s;
  position: relative;
  min-height: 65vh;
  :before {
    ${bgs}
    top: -80px;
    left: 0;
    background: url(${bgLeft}) no-repeat;
    background-size: contain;
	transform: translateX(0%);
	animation: ${bgRevealLeft} var(--base-duration) ease-in;
    animation-fill-mode: both;
    animation-delay: calc(var(--base-delay) + .5s);
    @media screen and (min-width: ${({ theme }) => theme.largeScreen}) {
      background: url(${bgLeftLarge}) no-repeat;
      top: -${(props) => props.theme.headerNavHeight};
      background-size: contain;
    }
  }
  :after {
    ${bgs}
    top: -${(props) => props.theme.headerNavHeight};
    right: 0;
    background: url(${bgRight}) no-repeat;
    background-position: right top;
	transform: translateX(0%);
	animation: ${bgRevealRight} var(--base-duration) ease-in;
    animation-fill-mode: both;
    animation-delay: calc(var(--base-delay) + .5s);
    @media screen and (min-width: ${({ theme }) => theme.tabletScreen}) {
      background: url(${bgRightLarge}) no-repeat;
      background-position: calc(.65*100vw) top;
      background-size: contain;
    }
    @media screen and (min-width: ${({ theme }) => theme.largeScreen}) {
      background: url(${bgRightLarge}) no-repeat;
      background-position: calc(.6*100vw) top;
      background-size: contain;
    }

  }
`;

export const ContentContainer = styled.div`
  grid-column: content;
  align-self: center;
  min-height: 404px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 3rem;
  @media screen and (min-width: ${({ theme }) => theme.tabletScreen}) {
    align-items: start;
    text-align: left;
  }
  @media screen and (min-width: ${({ theme }) => theme.largeScreen}) {
    align-items: start;
    padding: 0;
  }
  h1 span {
    display: block;
    position: relative;
  }
  h1 span:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
    transform-origin: 100% 0;
    animation: ${reveal} calc(var(--base-duration) - 0.5s) ease-in;
    animation-fill-mode: forwards;
    animation-delay: var(--base-delay);
  }
`;

export const SubHeading = styled.p`
  font-family: "DroidSerif", serif;
  font-size: 20px;
  line-height: 115%;
  letter-spacing: 0.23px;
  margin-bottom: 1.625rem;
  color: #aeaeae;
  animation: ${verticalOpacity} var(--base-duration) ease-in;
  animation-fill-mode: backwards;
  animation-delay: var(--base-delay);
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${({ theme }) => theme.tabletScreen}) {
    flex-direction: row;
    & div:first-child {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  & div:first-child {
    margin-bottom: 1rem;
  }
  & div {
    @media screen and (max-width: ${({ theme }) => theme.tabletScreen}) {
      width: 13.125rem;
      height: 3.7rem;
    }
  }
  animation: ${verticalOpacity} var(--base-duration) ease-in;
  animation-fill-mode: backwards;
  animation-delay: calc(var(--stagger-interval) + var(--base-delay));
`;
