import styled, { keyframes, css } from "styled-components";
import { Card } from "../../UI/Card.styled";
import { Heading3, Text } from "../../UI/Typography/Typography.styled";

const fadeOut = keyframes`
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
`;

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

export const Container = styled.div`
  display: grid;
  grid-gap: 2rem;
  @media screen and (min-width: ${({ theme }) => theme.tabletScreen}) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

export const TabsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const ContentContainer = styled.div`
  animation: ${(props) =>
    props.animate
      ? css`
          ${fadeOut} .2s ease-out
        `
      : css`
          ${fadeIn} .2s ease-out
        `};
  animation-fill-mode: forwards;
`;

export const WhyUsTab = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-width: 50px;
  height: initial;
  border: none;
  background: ${(props) =>
    props.active
      ? `linear-gradient(
      222.84deg,
      #0ce4f3 -60%,
      #37aaef 14.72%,
      #37aaef 14.72%,
      #37aaef 14.72%,
      #5386ec 81.06%,
      #952de5 165.84%
    )`
      : "transparent"};

  :hover .iconOutline {
    stroke: white;
  }
  & .iconOutline {
    stroke: ${(props) => (props.active ? "white" : null)};
  }
  & .icon {
    fill: ${(props) => (props.active ? "white" : null)};
  }
`;

export const TabTitle = styled(Heading3)`
  color: ${(props) => (props.active ? "white" : null)};
`;

export const TabDesc = styled(Text)`
  color: ${(props) => (props.active ? "white" : null)};
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.tabletScreen}) {
    display: block;
  }
`;
