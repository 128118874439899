import React from "react";
import PropTypes from "prop-types";
import { ListContainer } from "./ServiceList.styled";
import { Heading3, Text, Icon } from "../../UI/Typography/Typography.styled";
import { Card } from "../../UI/Card.styled";
import { Link, navigate } from "gatsby";
const ServiceList = ({ data }) => {
  const handleRedirect = (link) => {
    navigate(`/services/${link.toLowerCase().replace(/\s|\//g, "-")}`);
  };
  return (
    <ListContainer>
      {data.map((item) => (
        <Card key={item.name} onClick={() => handleRedirect(item.name)}>
          <Icon>{item.icon}</Icon>
          <Heading3 secondary center>
            <Link
              to={`/services/${item.name.toLowerCase().replace(/\s|\//g, "-")}`}
            >
              {item.name}
            </Link>
          </Heading3>
          <Text>{item.text}</Text>
        </Card>
      ))}
    </ListContainer>
  );
};

ServiceList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ServiceList;
