import React from "react";

export const box = (
  <svg
    width="79"
    height="79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
  >
    <rect
      x="1"
      y="1"
      width="77"
      height="77"
      rx="38.5"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
      className="iconOutline"
    />
    <path
      className="icon"
      d="M39.57 20.18L21.5 27v22.64l18 6.75 18-6.75V27l-17.93-6.82zm13.852 7.523l-5.484 2.04-13.852-5.204 5.414-2.11 13.922 5.274zM39.5 32.977l-13.922-5.274 5.485-2.039 13.921 5.203-5.484 2.11zM23.61 29.25l14.835 5.555v18.914L23.61 48.164V29.25zm16.945 24.469V34.805L55.39 29.25v18.914L40.555 53.72z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="30.654"
        y1="-46.638"
        x2="-62.621"
        y2="50.054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#37AAEF" />
        <stop offset="1" stopColor="#666BE9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="57.475"
        y1="-41.407"
        x2="-43.215"
        y2="55.019"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0CE4F3" />
        <stop offset=".331" stopColor="#37AAEF" />
        <stop offset=".331" stopColor="#37AAEF" />
        <stop offset=".331" stopColor="#37AAEF" />
        <stop offset=".625" stopColor="#5386EC" />
        <stop offset="1" stopColor="#952DE5" />
      </linearGradient>
    </defs>
  </svg>
);

export const layers = (
  <svg
    width="79"
    height="79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
  >
    <rect
      x="1"
      y="1"
      width="77"
      height="77"
      rx="38.5"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
      className="iconOutline"
    />
    <path
      className="icon"
      d="M44.773 22.36H21.5v19.054h23.273V22.36zm-2.109 16.945H23.61V24.469h19.055v14.836zm8.508 8.508H27.828v-4.22h2.11v2.04h19.125V30.867h-2.11v-2.18h4.219v19.125zM57.5 35.085V54.14H34.227v-4.22h2.109v2.11H55.39V37.195h-2.11v-2.11H57.5z"
      fill="url(#paint1_linear)"
    />
  </svg>
);

export const page = (
  <svg
    width="79"
    height="79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
  >
    <rect
      x="1"
      y="1"
      width="77"
      height="77"
      rx="38.5"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
      className="iconOutline"
    />
    <path
      className="icon"
      d="M41.96 20.25H25.72v36H53.28V31.5l-11.32-11.25zm.704 3.656l6.961 6.961h-6.96v-6.96zM27.828 54.141V22.359h12.727v10.618h10.617V54.14H27.828z"
      fill="url(#paint1_linear)"
    />
  </svg>
);

export const drawer = (
  <svg
    width="79"
    height="79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
  >
    <rect
      x="1"
      y="1"
      width="77"
      height="77"
      rx="38.5"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
      className="iconOutline"
    />
    <path
      className="icon"
      d="M50.117 46.688c-.422 0-.844.093-1.265.28a3.75 3.75 0 00-.985.704c-.234.281-.469.61-.703.984-.187.375-.258.797-.21 1.266a2.1 2.1 0 00.21 1.195c.234.375.469.727.703 1.055.281.281.61.516.985.703.421.14.843.21 1.265.21.469 0 .867-.07 1.196-.21a5.17 5.17 0 001.054-.703c.328-.328.563-.68.703-1.055.14-.375.235-.773.282-1.195a5.024 5.024 0 00-.282-1.266 2.393 2.393 0 00-.703-.984 5.162 5.162 0 00-1.054-.703c-.329-.188-.727-.282-1.196-.282zm.07 4.289c-.234 0-.398-.024-.492-.07a2.308 2.308 0 00-.351-.282 1.103 1.103 0 01-.211-.281c0-.14-.024-.282-.07-.422.046-.14.07-.281.07-.422a.883.883 0 01.21-.352c.141-.093.259-.164.352-.21.094-.047.258-.07.492-.07.094 0 .211.023.352.07.14.046.234.117.281.21.094.094.188.211.282.352.093.14.117.281.07.422a.465.465 0 01-.07.422l-.282.281a.795.795 0 01-.28.281c-.142.047-.259.07-.352.07zM57.5 26.93l-6.117-6.68H27.687l-6.117 6.54-.07 13.64h2.04l-2.04 2.46.07 13.36H57.5l.07-13.29-2.32-2.53h2.32l-.07-13.5zm-28.828-4.57h21.726l4.008 4.218H24.594l4.078-4.219zm-5.063 31.78l.07-8.507h31.712l.07 8.508H23.609zM55.25 43.524H23.68l2.601-3.093h26.227l2.742 3.093zM23.61 38.32l.07-9.633h31.71l.07 9.633H23.61zm26.578-7.453c-.516 0-.961.094-1.336.281-.329.141-.657.352-.985.633-.281.282-.515.61-.703.985a5.02 5.02 0 00-.281 1.265c.047.422.14.844.281 1.266.188.375.422.703.703.984.328.282.656.516.985.703.375.141.82.211 1.336.211.374 0 .75-.07 1.124-.21a3.457 3.457 0 001.055-.704c.281-.281.516-.61.703-.984.188-.422.258-.844.211-1.266.047-.468-.023-.89-.21-1.265a3.756 3.756 0 00-.704-.985 2.65 2.65 0 00-1.054-.633 2.496 2.496 0 00-1.126-.28zm-.07 4.219c-.141 0-.282-.023-.423-.07a2.302 2.302 0 01-.351-.282 1.102 1.102 0 00-.211-.28.65.65 0 010-.423.65.65 0 010-.422 3.43 3.43 0 00.21-.351.883.883 0 01.352-.211c.14-.047.282-.07.422-.07.188 0 .328.023.422.07.094.047.188.117.281.21.14.095.235.212.282.352l.14.422-.14.422a.797.797 0 01-.282.281l-.28.282c-.095.047-.235.07-.423.07z"
      fill="url(#paint1_linear)"
    />
  </svg>
);
