import React from "react";
import PropTypes from "prop-types";
import {
  ButtonStyled,
  ButtonOuter,
  ButtonText,
  LinkStyled,
} from "./Button.styled";

const Button = ({ secondary, type, children, onClick, link }) => {
  const ButtonType = link ? (
    <LinkStyled to={link} secondary={secondary ? 1 : 0}>
      <ButtonText>{children}</ButtonText>
    </LinkStyled>
  ) : (
    <ButtonStyled type={type} secondary={secondary}>
      <ButtonText>{children}</ButtonText>
    </ButtonStyled>
  );
  return <ButtonOuter onClick={onClick || null}>{ButtonType}</ButtonOuter>;
};

Button.propTypes = {
  secondary: PropTypes.bool,
  onClick: PropTypes.func,
  link: PropTypes.string,
  type: function (props, propName) {
    if (!props["link"] && props[propName] === undefined) {
      return new Error("Please provide a type prop!");
    }
  },
};

export default Button;
