import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Text, Icon, Heading3 } from "../../UI/Typography/Typography.styled";
import { HR } from "../../UI/HR.styled";
import {
  Container,
  TabsContainer,
  WhyUsTab,
  TabDesc,
  TabTitle,
  ContentContainer,
} from "./WhyUs.styled";
const WhyUsList = ({ data, currentItem, setCurrentItem }) => {
  const [animate, setAnimateOnExit] = useState(false);
  const currentTab = useRef(0);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setAnimateOnExit(false);
      setCurrentItem(currentTab.current);
    }, 300);
    return () => clearTimeout(timeout);
  }, [animate, setCurrentItem]);

  function handleTabClick(index) {
    setAnimateOnExit(true);
    currentTab.current = index;
  }

  return (
    <Container>
      <TabsContainer>
        {data.map((item, i) => (
          <WhyUsTab
            key={item.name}
            as="button"
            type="button"
            active={i === currentTab.current}
            onClick={() => handleTabClick(i)}
          >
            <Icon>{item.icon}</Icon>
            <TabTitle active={i === currentTab.current} secondary center>
              {item.name}
            </TabTitle>
            <TabDesc active={i === currentTab.current}>{item.desc}</TabDesc>
          </WhyUsTab>
        ))}
      </TabsContainer>
      <ContentContainer animate={animate}>
        <Heading3 as="h4">{data[currentItem].title}</Heading3>
        <HR secondary></HR>
        <Text lineHeight="28px">{data[currentItem].text}</Text>
      </ContentContainer>
    </Container>
  );
};
WhyUsList.propTypes = {
  data: PropTypes.array.isRequired,
  currentItem: PropTypes.number.isRequired,
  setCurrentItem: PropTypes.func.isRequired,
};
export default WhyUsList;
