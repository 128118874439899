import React from "react";

import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import Services from "../components/Services/Services";
import WhyUs from "../components/WhyUs/WhyUs";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Serbay Digital Agency: React Web, Mobile Apps, Web Design"
      description="Serbay is a digital agency offering high-quality SEO services and web development services at a fraction of what it normally costs."
    />
    <Hero></Hero>
    <Services></Services>
    <WhyUs></WhyUs>
  </Layout>
);

export default IndexPage;
