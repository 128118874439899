import styled from "styled-components";

export const Card = styled.li`
  display: grid;
  justify-items: center;
  align-content: center;
  text-align: center;
  min-width: 300px;
  flex-basis: 33%;
  transition: cursor 0.2s ease-in-out, background 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  border-radius: 8px;
  padding: 2rem;
  & > * {
    transform: color 0.2s ease-in-out;
  }
  :hover {
    cursor: pointer;
    background: linear-gradient(
      222.84deg,
      #0ce4f3 -60%,
      #37aaef 14.72%,
      #37aaef 14.72%,
      #37aaef 14.72%,
      #5386ec 81.06%,
      #952de5 165.84%
    );
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25);
    transform: scale(1.05);
  }
  :hover > * {
    color: white;
  }
  .icon {
    transition: fill 0.2s ease-in-out;
  }
  :hover .icon {
    fill: white;
  }
`;
