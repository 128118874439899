import React, { useState } from "react";
import { Container } from "../Layout/LayoutElements.styled";
import { SubHeading, Heading } from "../UI/Typography/Typography.styled";
import { HR } from "../UI/HR.styled";
import WhyUsList from "./WhyUsList/WhyUsList";
import { box, layers, page, drawer } from "./icons";

const listData = [
  {
    icon: box,
    name: "Prioritize You",
    desc: "What is your ultimate goal?",
    title: "Your Mission Is Our Priority",
    text:
      "What is your ultimate goal in creating your website or app, and what action do you want your prospect to take? Do you want to pique their interest or do you want them to finalize a purchase? Maybe you’re an authority in your chosen field, and you want to share what you know with more people online. Here’s how it works: we’ll sit down with you, take a closer look at your plans, and share our insights on how we can turn these into reality. We’ll initiate strategies and make sure to see it through, because your goals are our goals. No task is too big or small for us, as long as it leads your business to wherever you want it to be.",
  },
  {
    icon: layers,
    name: "Cost-Effective",
    desc: "How Much Good Quality Is Worth To You?",
    title: "We Offer Cost-Effective Solutions",
    text:
      "When it comes to web and mobile development, we know what we’re doing, and we’re good at what we do. After all, we’re web development professionals ourselves, and we’ve previously handled successful projects as full-time employees and IT consultants. We also know from experience just how much these services cost, so we made it our goal to make them more affordable for our clients. Affordable doesn’t mean second-class though. The quality of our services is at par with the ones offered by bigger players. We know your frustrations and fears when it comes to this kind of promise, especially when so many have let you down, but trust us when we say that it’s a promise we intend to keep. ",
  },
  {
    icon: page,
    name: "Customize",
    desc: "Would You Like To Stand Out?",
    title: "We Customize Our Projects",
    text:
      "Different strategies bring different results, especially if you cater to different folks. There’s not a single magical tool or strategy that can bring about your desired results when marketing online. That’s why we combine digital marketing solutions, each of them customized according to your needs and goals. We tweak our web development services, tailor your mobile app, and even fine-tune our AdWords and SEO services based on what your brand needs to achieve right now. Our services will never be the same for all of our clients: each of them have their own custom-fit web development and digital marketing solutions. This way, your business can stand a bigger chance of beating the competition and attracting more clients through these online channels.",
  },
  {
    icon: drawer,
    name: "Technology Stack",
    desc: "Want the latest and greatest technology implementation?",
    title: "We Use Up-To-Date Technologies",
    text:
      "We know that technologies are ever-changing. Given the pace of technological developments happening in a blink of an eye, solutions that work wonders now soon become obsolete. That’s why we make sure to follow the latest in search engine optimization, digital marketing, and web development. You can trust that our team will be always on their toes, so that they can be the first ones to know about Google algorithm updates . This is part of Serbay Digital’s dedication to deliver nothing but the best services to our clients. That way, we are able to fine-tune our strategies to make sure your business remains competitive. When you work with Serbay Digital, your brand will never be left behind.",
  },
];

const WhyUs = () => {
  const [currentItem, setCurrentItem] = useState(0);
  return (
    <Container as="section" marginBottom>
      <Heading as="h2" h2>
        WHY WORK WITH US?
      </Heading>
      <SubHeading>
        There are so many entrepreneurs and small business owners out there who
        invest top dollar for marketing services that are low-tier and low
        quality.
      </SubHeading>
      <SubHeading>
        Our goal? To make sure this doesn’t happen to you.
      </SubHeading>
      <HR></HR>
      <WhyUsList
        data={listData}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
      ></WhyUsList>
    </Container>
  );
};

export default WhyUs;
