import React from "react";
import { Container } from "../Layout/LayoutElements.styled";
import { Heading, SubHeading } from "../UI/Typography/Typography.styled";
import ServiceList from "./ServiceList/ServiceList";
import { HR } from "../UI/HR.styled";

import { gear, carryCase, roller, bar, world } from "./icons";

const listData = [
  {
    icon: gear,
    name: "Mobile App Development",
    text:
      "Bring your business to your customers’ fingertips with a simple-to-use, visually stunning app.",
  },
  {
    icon: carryCase,
    name: "Custom Website Development",
    text:
      "Create a solid online presence with a compelling, attention-grabbing website that’s built to beat your competition.",
  },
  {
    icon: roller,
    name: "Website Design",
    text:
      "Make it easy for your customers to browse through your content or buy from you with user-friendly website design",
  },
  //   {
  //     icon: paint,
  //     name: "Brand Design",
  //     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //   },
  {
    icon: bar,
    name: "Digital Marketing",
    text:
      "Spread the word about your business online with a brand that engages your customers and attracts new prospects.",
  },
  {
    icon: world,
    name: "Search Engine Optimization",
    text:
      "Push your website to the top of Google search results and find new clients with a whitehat SEO strategy",
  },
];

const Services = () => {
  return (
    <Container as="section" marginBottom>
      <Heading as="h2" h2>
        WHAT WE DO
      </Heading>
      <SubHeading>
        We’re user-oriented. We’re fast. We know what we’re doing. Here’s what
        we offer:
      </SubHeading>
      <HR></HR>
      <ServiceList data={listData}></ServiceList>
    </Container>
  );
};

export default Services;
