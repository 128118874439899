import styled, { css } from "styled-components";
import { Link } from "gatsby";

export const ButtonOuter = styled.div`
  position: relative;
  background: ${(props) => props.theme.primaryBg};
  border-radius: 24px;
  padding: 3px;
  width: 8.5rem;
  height: 3rem;
  margin-bottom: 1rem;
  :after {
    border-radius: 23.5px;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      18.25deg,
      #0ce4f3 -60%,
      #37aaef 14.72%,
      #37aaef 14.72%,
      #37aaef 14.72%,
      #5386ec 81.06%,
      #952de5 165.84%
    );
    opacity: 0;
    transition: opacity 0.5s ease-out, color 0.5s ease-out;
  }
  :hover:after {
    opacity: 1;
  }
`;

const buttonStyles = css`
  background: ${(props) => (props.secondary ? "white" : "transparent")};
  border: none;
  border-radius: 24px;
  color: ${(props) => (props.secondary ? "#4764E6" : "white")};
  width: 100%;
  height: 100%;
  padding: 0.1rem;
  border-color: transparent;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 2.375rem;
  position: ${(props) => (props.secondary ? "relative" : null)};
  z-index: ${(props) => (props.secondary ? 2 : null)};
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.228571px;
  position: relative;
  z-index: 4;
  :hover {
    cursor: pointer;
  }
`;

export const ButtonStyled = styled.button`
  ${buttonStyles}
`;

export const LinkStyled = styled(Link)`
  ${buttonStyles}
`;

export const ButtonText = styled.span`
  position: relative;
  z-index: 3;
`;
