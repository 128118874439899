import React from "react";
import {
  Header,
  SubHeading,
  ContentContainer,
  ButtonContainer,
} from "./Hero.styled";
import Button from "../UI/Button/Button";
import { Heading } from "../UI/Typography/Typography.styled";

const Hero = () => {
  return (
    <Header as="header" fullWidth inherit>
      <ContentContainer>
        <Heading>
          <span>A DIGITAL AGENCY</span>
          <span>THAT DELIVERS</span>
        </Heading>
        <SubHeading>
          Never settle for broken promises that come with a high price.
        </SubHeading>
        <ButtonContainer>
          <Button link="/services">Read More</Button>
          <Button link="/contact" secondary>
            Contact Us
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Header>
  );
};

export default Hero;
